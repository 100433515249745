<template>
    <header class="main-header sticky-top">
        <nav class="navbar navbar-expand-lg">
            <div class="navbar-brand">
                <a
                href="https://www.laposte.fr"
                target="_blank"
                title="Aller sur le site de La Poste"
                >
                    <img src="@/assets/img/logo-laposte.svg" style="height: auto; max-width: 100%; max-height: 49px; display: block;" alt="La Poste" />
                </a>
                <a
                    href="https://www.mesmedicamentschezmoi.com"
                    target="_blank"
                    title="Aller sur le site de Mes médicaments chez moi"
                >
                    <img
                        class="logo-mmcm"
                        src="@/assets/img/logo-mmcm.png"
                        alt="Mes médicaments chez moi"
                    />
                </a>
                <router-link :to="{ name: 'Home' }" title="Tableau de bord">
                    Aidants
                </router-link>
            </div>

            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main-nav"
                aria-controls="main-nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="main-nav">
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main-nav"
                    aria-controls="main-nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i class="fal fa-times"></i>
                </button>
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item" :class="{ active: isActive('dashboard') }">
                        <router-link :to="{ name: 'Home' }" class="nav-link single-link">
                        Tableau de bord
                        </router-link>
                    </li>

                    <!-- BENEFICIAIRES -->
                    <li class="nav-item dropdown" :class="{ active: isActive('beneficiaires') }">
                        <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        >
                            Bénéficiaires
                        </a>
                        <div class="dropdown-menu">
                            <router-link
                                class="dropdown-item"
                                :to="{ name: 'BeneficiaireAjout' }"
                            >
                                Ajouter un bénéficiaire
                            </router-link>
                            <router-link
                                class="dropdown-item"
                                :to="{ name: 'BeneficiairesListe' }"
                            >
                                Liste de mes bénéficiaires
                            </router-link>
                        </div>
                    </li>

                    <!-- LIVRAISONS -->
                    <li class="nav-item dropdown" :class="{ active: isActive('livraisons') }">
                        <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        >
                            Livraisons
                        </a>
                        <div class="dropdown-menu">
                            <router-link
                                class="dropdown-item"
                                :to="{ name: 'LivraisonNew-step1' }"
                            >
                                Commander une livraison pour un bénéficiaire
                            </router-link>
                            <router-link
                                class="dropdown-item"
                                :to="{ name: 'LivraisonsEncours' }"
                            >
                                Livraisons en cours
                            </router-link>
                            <router-link
                                class="dropdown-item"
                                :to="{ name: 'LivraisonsArchivees' }"
                            >
                                Livraisons archivées
                            </router-link>
                        </div>
                    </li>

                    <!-- RENDEZ VOUS -->
                    <li class="nav-item dropdown" :class="{ active: isActive('rdv') }">
                        <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        >
                            Rendez-vous
                        </a>
                        <div class="dropdown-menu">
                            <router-link
                                class="dropdown-item"
                                :to="{ name: 'Rdv-step1' }"
                            >
                                Prendre un rendez-vous pour un bénéficiaire
                            </router-link>
                            <router-link
                                class="dropdown-item"
                                :to="{ name: 'RdvEncours' }"
                            >
                                Rendez-vous en cours
                            </router-link>
                            <router-link
                                class="dropdown-item"
                                :to="{ name: 'RdvArchives' }"
                            >
                                Rendez-vous archivés
                            </router-link>
                        </div>
                    </li>

                    <!-- CONTACT -->
                    <li class="nav-item" :class="{ active: isActive('contact') }">
                        <router-link :to="{ name: 'Contact' }" class="nav-link single-link">
                            Nous contacter
                        </router-link>
                    </li>

                    <!-- AIDE -->
                    <li class="nav-item" :class="{ active: isActive('aide') }">
                        <router-link :to="{ name: 'Aide' }" class="nav-link single-link">
                            <i class="fal fa-question-circle me-2"></i> Aide
                        </router-link>
                    </li>

                    <!-- COMPTE -->
                    <li class="nav-item dropdown" :class="{ active: isActive('compte') }">
                        <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        >
                            <i class="far fa-user"></i>
                        </a>
                        <div class="dropdown-menu">
                            <router-link class="dropdown-item" :to="{ name: 'MonCompte' }">
                                Mon compte
                            </router-link>
                            <a class="dropdown-item" @click.prevent="logout">Déconnexion</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
// const $ = window.$;
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
    name: "HeaderAidants",
    props: {},
    methods: {
        isActive: function(menuItem) {
            return this.$route.meta.menuActive === menuItem;
        },
        logout() {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$router.push("/connexion");
            });
        }
    },
};
</script>

<style scoped lang="scss">
    .dropdown-item{
        cursor: pointer;
    }
</style>

