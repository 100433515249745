import axios from "axios";

const state = { 
    typesRdv: [],
}

const getters = {
    getTypesRdv: (state) => state.typesRdv,    
};

const actions = {
    init: ({ commit }) => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API +"/fr/api/v2/aidant/init").then(resp => {
                commit('init', resp.data);
                resolve(resp.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
};

const mutations = {
    init: (state, resp) => {        
        state.typesRdv = resp.types_rdv        
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};