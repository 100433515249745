<template>
  <component :is="layout">
    <router-view />
    <revo-modal />
  </component>
</template>

<script>

import Default from "@/layouts/Default.vue";
import ConnexionLayout from "@/layouts/ConnexionLayout.vue";
import { USER_REQUEST } from "@/store/actions/user";
import { AUTH_LOGOUT } from "@/store/actions/auth";

const default_layout = "Default";

export default {
    name: "App",
    components: {
        Default,
        ConnexionLayout
    },
    computed: {
        layout() {
            return this.$route.meta.layout || default_layout;
        },
        isIdle() {
            return this.$store.state.idleVue.isIdle;
        }
    },
    watch: {
        isIdle(newVal){
            if(newVal === true) {
                if (this.$store.getters.isAuthenticated) {
                this.$store.dispatch(AUTH_LOGOUT).then(() => {
                    this.$router.push("/connexion");
                });
                }
            }
        }
    },
    created: function() {
        //if (this.$store.getters.isAuthenticated) {
            this.$store.dispatch(USER_REQUEST).then(() => {
            }).catch(() => {
              this.$router.push('/connexion')
            });
        //}
    },
    mounted() {
      this.$store.dispatch('init')
    }
};
</script>

<style style="scss"></style>
