<template>
    <div>
        <HeaderConnexion />
        <!-- CONTENT -->
        <slot />
        <Footer />
    </div>
</template>

<script>
import HeaderConnexion from "@/components/HeaderConnexion.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "ConnexionLayout",
    components: {
        HeaderConnexion,
        Footer
    },
};
</script>
