<template>
    <header class="main-header sticky-top">
        <nav class="navbar navbar-expand-lg">
            <div class="navbar-brand">
                <a
                href="https://www.laposte.fr"
                target="_blank"
                title="Aller sur le site de La Poste"
                >
                    <img src="@/assets/img/logo-laposte.svg" style="height: auto; max-width: 100%; max-height: 49px; display: block;" alt="La Poste" />
                </a>
                <a
                    href="https://www.mesmedicamentschezmoi.com"
                    target="_blank"
                    title="Aller sur le site de Mes médicaments chez moi"
                >
                    <img
                        class="logo-mmcm"
                        src="@/assets/img/logo-mmcm.png"
                        alt="Mes médicaments chez moi"
                    />
                </a>
                <router-link :to="{ name: 'Home' }">
                    Aidants
                </router-link>
            </div>

            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-target="#main-nav"
                aria-controls="main-nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="main-nav">
                <ul class="navbar-nav ml-auto">
                    <!-- CONTACT -->
                    <!-- <li class="nav-item" :class="{ active: isActive('contact') }"> -->
                    <li class="nav-item">
                        <router-link :to="{ name: 'Contact' }" class="nav-link single-link">
                            Nous contacter
                        </router-link>
                    </li>

                    <!-- AIDE -->
                    <!-- <li class="nav-item" :class="{ active: isActive('aide') }"> -->
                    <li class="nav-item">
                        <router-link :to="{ name: 'Aide' }" class="nav-link single-link">
                            <i class="fal fa-question-circle me-2"></i> Aide
                        </router-link>
                    </li>

                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
    name: "HeaderConnexion",
    // props: {},
    methods: {
        isActive(menuItem) {
            return this.$route.meta.menuActive === menuItem;
        },
        logout() {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
                this.$router.push("/connexion");
            });
        }
    }
};
</script>