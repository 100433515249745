<template>
    <div>
        <!-- Diagonal section -->
        <section class="relative">
            <div class="diagonal-section-bottom--flip-x bg-main-diagonal z-1">
                <div class="diagonal-size">
                    <div class="diagonal-container">
                        <figure class="icon-mobile">
                            <img src="@/assets/img/dashboard/iso-dashboard.svg" alt="Tableau de bord" loading="lazy">
                        </figure>
                        <h1>Tableau de bord</h1>
                    </div>
                </div>
            </div>
                <div class="diagonal-container z-2">
                    <figure class="icon">
                        <img src="@/assets/img/dashboard/iso-dashboard.svg" alt="Tableau de bord" loading="lazy">
                    </figure>
                </div>
        </section>

        <!-- CONTENT -->
        <div class="container">
            <section class="content">
                <div class="row">
                    <!-- BENEFICIAIRES / MON COMPTE -->
                    <div class="col-md-6 col-lg-4">
                        <div class="bloc-shadow">
                            <img
                            src="@/assets/img/dashboard/icon-beneficiaire.png"
                            alt="Bénéficiaires"
                            />
                            <ul class="list-unstyled">
                                <li>
                                    <router-link
                                    class="btn btn-primary"
                                    :to="{ name: 'BeneficiaireAjout' }"
                                    >
                                        Ajouter un bénéficiaire
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                    class="btn btn-primary"
                                    :to="{ name: 'BeneficiairesListe' }"
                                    >
                                        Liste de mes bénéficiaires
                                    </router-link>
                                </li>
                            </ul>
                        </div>

                        <div class="bloc-shadow">
                            <img
                            src="@/assets/img/dashboard/iso-compte.svg"
                            alt="Mon compte"
                            />
                            <ul class="list-unstyled">
                                <li>
                                    <router-link
                                    class="btn btn-primary"
                                    :to="{ name: 'MonCompte' }"
                                    >
                                        Mon compte
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- LIVRAISONS -->
                    <div class="col-md-6 col-lg-4">
                        <div class="bloc-shadow">
                            <img
                            src="@/assets/img/dashboard/icon-livraison.png"
                            alt="Livraisons"
                            />
                            <ul class="list-unstyled">
                                <li>
                                    <router-link
                                    class="btn btn-primary"
                                    :to="{ name: 'LivraisonNew-step1' }"
                                    >
                                        Organiser une livraison pour un bénéficiaire
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                    class="btn btn-primary"
                                    :to="{ name: 'LivraisonsEncours' }"
                                    >
                                        Livraisons en cours
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                    class="btn btn-primary"
                                    :to="{ name: 'LivraisonsArchivees' }"
                                    >
                                        Livraisons archivées
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- RDV -->
                    <div class="col-md-6 col-lg-4">
                        <div class="bloc-shadow">
                            <img src="@/assets/img/dashboard/icon-rdv.png" alt="Rendez-vous" />
                            <ul class="list-unstyled">
                                <li>
                                    <router-link
                                    class="btn btn-primary"
                                    :to="{ name: 'Rdv-step1' }"
                                    >
                                        Prendre un rendez-vous pour un bénéficiaire
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                    class="btn btn-primary"
                                    :to="{ name: 'RdvEncours' }"
                                    >
                                        Rendez-vous en cours
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                    class="btn btn-primary"
                                    :to="{ name: 'RdvArchives' }"
                                    >
                                        Rendez-vous archivés
                                    </router-link>
                                </li>                          
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "HomeAidants",
    components: {
    }
};
</script>

<style scoped lang="scss">
    .bando-head {
        @media screen and (min-width: 992px) {
            margin-bottom: 7rem;
        }
    }

    .container {
        @media screen and (min-width: 1400px) {
            max-width: 116rem;
        }
    }

    .content {
        .bloc-shadow {
            margin: 0 2rem 3rem;
            padding: 2rem;
            text-align: center;

            @media screen and (min-width: 768px) {
                 margin: 0 1rem 3rem;
                padding: 2rem;
            }

            @media screen and (min-width: 992px) {
                margin: 0 1rem 4.5rem;
                padding: 2rem 2.5rem;
            }

            @media screen and (min-width: 1200px) {
                margin: 0 2rem 4.5rem;
            }

            img {
                max-height: 14rem;
                max-width: 100%;
            }

            ul {
                margin: 1.5rem 0 0 0;

                li {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
</style>
