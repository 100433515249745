
import Home from "../views/Home.vue";
import store from "@/store";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/connexion");
};


const routes = [
  {
    path: "/",
    name: "Home",
    meta: { menuActive: "dashboard" },
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/connexion",
    name: "Connexion",
    meta: { layout: "ConnexionLayout" },
    component: () => import("../views/Connexion.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/resetpwd/:key",
    name: "ResetPWD",
    meta: { layout: "ConnexionLayout" },
    component: () => import("../views/ResetPwd.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/double-check",
    name: "DoubleCheck",
    meta: { layout: "ConnexionLayout" },
    component: () => import("../views/DoubleCheck.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/ajout-beneficiaire",
    name: "BeneficiaireAjout",
    meta: { menuActive: "beneficiaires" },
    component: () => import("../views/Beneficiaire/BeneficiaireEdit.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/liste-beneficiaires",
    name: "BeneficiairesListe",
    meta: { menuActive: "beneficiaires" },
    component: () => import("../views/Beneficiaire/BeneficiairesListe.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/beneficiaire/:id?",
    name: "BeneficiaireFiche",
    meta: { menuActive: "beneficiaires" },
    component: () => import("../views/Beneficiaire/BeneficiaireEdit.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouvelle-livraison",
    name: "LivraisonNew-step1",
    meta: { menuActive: "livraisons" },
    component: () => import("../views/Livraison/LivraisonNew-step1.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouvelle-livraison-pharmacie/:id",
    name: "LivraisonNew-step2",
    meta: { menuActive: "livraisons" },
    component: () => import("../views/Livraison/LivraisonNew-step2.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouvelle-livraison-organisation/:id/:idPharmacie",
    name: "LivraisonNew-step3",
    meta: { menuActive: "livraisons" },
    component: () => import("../views/Livraison/LivraisonNew-step3.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouvelle-livraison-validation/:id",
    name: "LivraisonNew-step4",
    meta: { menuActive: "livraisons" },
    component: () => import("../views/Livraison/LivraisonNew-step4.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouvelle-livraison-recapitulatif/:id",
    name: "LivraisonNew-step5",
    meta: { menuActive: "livraisons" },
    component: () => import("../views/Livraison/LivraisonNew-step5.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/livraisons-encours",
    name: "LivraisonsEncours",
    meta: { menuActive: "livraisons" },
    component: () => import("../views/Livraison/LivraisonsEncours.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/livraisons-archivees",
    name: "LivraisonsArchivees",
    meta: { menuActive: "livraisons" },
    component: () => import("../views/Livraison/LivraisonsArchivees.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/fiche-livraison/:id",
    name: "LivraisonFiche",
    meta: { menuActive: "livraisons" },
    component: () => import("../views/Livraison/LivraisonFiche.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouveau-rendez-vous",
    name: "Rdv-step1",
    meta: { menuActive: "rdv" },
    component: () => import("../views/Rdv/Rdv-step1.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouveau-rendez-vous-pharmacie/:id",
    name: "Rdv-step2",
    meta: { menuActive: "rdv" },
    component: () => import("../views/Rdv/Rdv-step2.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouveau-rendez-vous-organisation/:id/:idPharmacie",
    name: "Rdv-step3",
    meta: { menuActive: "rdv" },
    component: () => import("../views/Rdv/Rdv-step3.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/nouveau-rendez-vous-recapitulatif/:id",
    name: "Rdv-step4",
    meta: { menuActive: "rdv" },
    component: () => import("../views/Rdv/Rdv-step4.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/rdv-encours",
    name: "RdvEncours",
    meta: { menuActive: "rdv" },
    component: () => import("../views/Rdv/RdvEncours.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/rdv-archives",
    name: "RdvArchives",
    meta: { menuActive: "rdv" },
    component: () => import("../views/Rdv/RdvArchives.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/mes-informations",
    name: "MonCompte",
    meta: { menuActive: "compte" },
    component: () => import("../views/MonCompte.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { menuActive: "contact"},  
    component: () => import("../views/Contact.vue"),
    beforeEnter: (to, from, next) => { 
      if(!store.getters.isAuthenticated) to.meta.layout = 'ConnexionLayout'
      else to.meta.layout = 'Default'
      next()
      return;
    }
  },
  {
    path: "/cguv",
    name: "CGU",
    meta: { menuActive: "contact"},  
    component: () => import("../views/CGU.vue"),
    beforeEnter: (to, from, next) => { 
      if(!store.getters.isAuthenticated) to.meta.layout = 'ConnexionLayout'
      else to.meta.layout = 'Default'
      next()
      return;
    }
  },
  {
    path: "/cookies",
    name: "Cookies",
    meta: { menuActive: "contact"},  
    component: () => import("../views/Cookies.vue"),
    beforeEnter: (to, from, next) => { 
      if(!store.getters.isAuthenticated) to.meta.layout = 'ConnexionLayout'
      else to.meta.layout = 'Default'
      next()
      return;
    }
  },
  {
    path: "/mentions-legales",
    name: "MentionsLegales",
    meta: { menuActive: "contact"},  
    component: () => import("../views/MentionsLegales.vue"),
    beforeEnter: (to, from, next) => { 
      if(!store.getters.isAuthenticated) to.meta.layout = 'ConnexionLayout'
      else to.meta.layout = 'Default'
      next()
      return;
    }
  },
  {
    path: "/aide",
    name: "Aide",
    meta: { menuActive: "aide"  },
    component: () => import("../views/Aide.vue"),    
    beforeEnter: (to, from, next) => { 
      if(!store.getters.isAuthenticated) to.meta.layout = 'ConnexionLayout'
      else to.meta.layout = 'Default'
      next()
      return;
    }
  },
];

export default routes;
