import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import routes from './router'
import store from "./store"
import vuetify from './plugins/vuetify'
import VCalendar from 'v-calendar'
import VueSweetalert2 from 'vue-sweetalert2'
import VueUploadComponent from 'vue-upload-component'
import { Icon } from 'leaflet'
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet"
import filters from '@/filters'
import VueTheMask from 'vue-the-mask'
import RevoModalPlugin from './plugins/revomodal/revomodal'
import IdleVue from "idle-vue"
import mitt from 'mitt'

import 'leaflet/dist/leaflet.css'
import 'v-calendar/style.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import './assets/css/mmcm.scss'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

const emitter = mitt()

const app = createApp(App)

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

app.use(router)
app.use(store)
app.use(VCalendar, {
  componentPrefix: 'vc', // Now use vc-calendar and vc-date-picker
})
app.use(VueSweetalert2)
app.use(RevoModalPlugin)
app.use(VueTheMask)
app.use(vuetify)
app.use(IdleVue, {
  eventEmitter: emitter,
  store,
  idleTime: 1200000, // 20min
  startAtIdle: false
})

app.component('file-upload', VueUploadComponent)
app.component('l-map', LMap)
app.component('l-tile-layer', LTileLayer)
app.component('l-marker', LMarker)

app.config.globalProperties.emitter = emitter
app.config.globalProperties.$filters = filters

app.mount('#app')
