<template>
    <div>
        <Header />
        <!-- CONTENT -->
        <slot />
        <Footer />
        <!-- bg noir au clic sur menu -->
        <div class="bg"></div>
    </div>
</template>

<script>
const $ = window.$;
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "DefaultLayout",
    components: {
        Header,
        Footer
    },
    watch:{
        $route (){
            $(".bg").removeClass("show");
        }
    },

    mounted() {
        // TOGGLE BG
        $(".dropdown-toggle").click(function(){            
            if($(this).hasClass('show')){
                $(".bg").removeClass("show");
            }else{
                $(".bg").addClass("show");
            }
            
        });

        $(".bg").click(function(){
            $(this).removeClass("show");          
        });

        $(".dropdown-item").click(function(){
            $(".bg").removeClass("show");          
        });
    }
};
</script>
