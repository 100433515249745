// we need our modal component
import RevoModal from './RevoModal.vue'

export default {
  install: (app) => {
    app.component('revo-modal', RevoModal)
    app.config.globalProperties.$revoModal = {
      show(params) {
        app.config.globalProperties.emitter.emit('show', params)
      },
      close(params) {
        app.config.globalProperties.emitter.emit('close', params)
      },
      confirm(params) {
        app.config.globalProperties.emitter.emit('confirm', params)
      },
      cancel(params) {
        app.config.globalProperties.emitter.emit('close', params)
      }
    }
  },
};
