import moment from 'moment'

const filters = {
  dico(mot) {
    let dico = {
      NO_STATUS: "Commande créée",
      ERROR_ORDER: "Erreur lors du paiement de la commande",
      PENDING: "En cours",
      DONE: "Terminé",
      CANCELLED: "Annulé",
      ORDER_ACCEPTED: "En cours de traitement",
      ORDER_PLACED: "En cours de traitement",
      MEDICAL_ORDER_PHARMACY_RECEIVED: "En cours de traitement",
      MEDICAL_ORDER_COLLECTED: "En cours de traitement",
      MEDICATIONS_BAG_READY: "En cours de traitement",
      MEDICATIONS_BAG_COLLECTED: "Livraison préparée",
      MEDICATIONS_BAG_DELIVERED_ORDER_IS_CLOSED: "Livraison effectuée",
      PHARMACY_ENROLLMENT_SUCCESSFUL: "Commande en attente de confirmation",
      mmcm: "Facteur",
      collaborateur: "Collaborateur de la pharmacie",
      coursier: "Coursier",
      stuart: "Coursier",
      m: "M.",
      mme: "Mme"
    }

    if (dico[mot]) return dico[mot]
    return mot
  },
  formatDate(date, format = 'DD MMMM YYYY - HH:mm') {
    return date ? moment(date).locale("fr").format(format) : "";
  },
  addDate(date, amount, unit) {
    let endCreneau = moment(date);
    return endCreneau.add(amount, unit);
  },
  formatDistance(distance) {
    if (distance > 1000) {
      let km = distance / 1000;
      return km.toFixed(1) + " km"
    } else {
      let m = parseFloat(distance);
      return m.toFixed(0) + " m"
    }
  },
  formatPrice(price) {
    if (!price) return 0
    if (price < 0) return 0
    return parseFloat(price).toFixed(2)
  }
}
export default filters;
