<template>
    <footer class="main-footer">
        <ul class="nav">
            <li>© La Poste 2021 - Tous droits réservés</li>
            <li>
            <router-link :to="{ name: 'Contact' }">
                Nous contacter
            </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'MentionsLegales' }" title="Mentions légales">
                    Mentions légales
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'CGU' }" title="CGU">
                    Conditions Générales d’Utilisation
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'Cookies' }" title="Cookies">
                    Information sur les cookies
                </router-link>
            </li>
            <li>
            <a
                href="https://www.laposte.fr/politique-de-protection-des-donnees"
                target="_blank"
                >Politique de Protection des Données Personnelles</a
            >
            </li>
        </ul>
        <div class="logo">
            <a
            href="https://www.laposte.fr/"
            target="_blank"
            title="Aller sur le site de La Poste"
            >
            <img src="@/assets/img/logo-laposte_mini.svg" style="height: auto; max-width: 100%; max-height: 41px; display: block; vertical-align: middle;" alt="La Poste" />
            </a>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterAidants",
        props: {}
    };
</script>

<style scoped lang="scss">
    .main-footer {
        text-align: center;

        @media screen and (min-width: 992px) {
            height: 12rem;
        }

        .nav {
            background: var(--darkgrey);
            color: white;
            display: block;
            padding: 1.5rem 0;

            @media screen and (min-width: 992px) {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 6rem;
                padding: 0;
            }

            li {
                list-style: none;
                font-size: 1.3rem;
                font-weight: 500;

                @media screen and (min-width: 992px) {
                    &:after {
                    content: "-";
                    margin: 0 0.5rem;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }

                a {
                    color: white;
                    display: inline-block;
                    padding: 0.5rem;

                    @media screen and (min-width: 992px) {
                        padding: 0;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .logo {
            background: var(--yellow);
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
