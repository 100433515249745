import { createStore } from 'vuex'
import user from "./modules/user";
import auth from "./modules/auth";
import app from './modules/app';

const store = createStore({
    state: {
      count: 0
    },
    mutations: {
      increment (state) {
        state.count++
      }
    },
    modules: {
        app,
        auth,
        user
      },
  })

export default store;
