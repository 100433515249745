<template>
    <div class="revomodal" v-show="open">
        <div class="revomodal-bg" @click="close()"></div>
        <div class="revomodal-content">
            <button type="button" aria-label="Close" class="btn-close" @click="close()"><i class="fal fa-times-circle" aria-hidden="true"></i></button>
            <div class="modal-body modal-center">
                <template v-if="type == 'message' || type == 'confirm'  || type == 'error'">
                    <h2>{{ title }}</h2>
                    <p>{{ text }}</p>
                    <template v-if="type == 'confirm'">
                        <div class="buttons">
                            <button
                                type="button"
                                @click="close()"
                                class="btn btn-secondary mx-3"
                            >
                                Non
                            </button>
                            <button
                                type="button"                
                                class="btn btn-primary mx-3"
                                @click="confirm()"
                            >
                                Oui
                            </button>
                        </div>                       
                    </template>
                </template>
                <template v-if="type == 'component'">
                    <component :is="compo" v-bind="compoProps" />
                </template>
            </div>
        </div>
    </div>
</template>
<script>

// import RevoModalPlugin from './revomodal'
// import Vue from 'vue'

export default {
    name: "RevoModal",
    data() {
        return {
            open: false,
            type: 'message',
            title: '',
            text: '',
            // adding callback function variable
            onConfirm: null,
            onClose: null,
            compo: null,
            compoProps: null,
            emittedParam: null,
        }
    },    
    methods: {
        confirm() {
            this.open = false
            //document.body.classList.remove("noscroll");
            if(this.onConfirm){
                this.onConfirm(this.emittedParam)
            }
            this.$emit('confirm')
        },
        close() {
            this.open = false
            //document.body.classList.remove("noscroll");
            if(this.onClose){
                this.onClose(this.emittedParam)
            }
            this.$emit('close')
        }
    },
    beforeMount() {        
        // here we need to listen for emited events
        // we declared those events inside our plugin
        this.emitter.on('show', (params) => {
            
            this.open = true,
            this.title = ''
            this.text = '';    
            this.onConfirm = null;
            this.onClose = null;
            this.compo= null;
            this.compoProps =null;
            this.emittedParam = null;

            if(params.type){
                this.type = params.type
            }
            if(params.title){
                this.title = params.title
            }
            if(params.text){
                this.text = params.text
            }
            
            if(this.type == "component"){
                if(params.component){                     
                    if(params.componentProps){
                        this.compoProps = params.componentProps
                    }
                    this.compo = params.component
                    // this.compo = Vue.component(
                    //     'modal-async-comp',
                    //     // La fonction `import` dynamique retourne une `Promise`.
                    //     () => import(params.component)
                    // )

                    /*this.compo = Vue.component('modal-async-comp', (resolve) => {
                        // Cette syntaxe spéciale `require` indique à webpack de
                        // diviser automatiquement votre code en sortie en paquets
                        // qui seront chargés via des requêtes AJAX.
                        require([params.component], resolve)
                    })*/
                }
            }

            if(params.onConfirm){
                this.onConfirm = params.onConfirm    
            }

            if(params.onClose){
                this.onClose = params.onClose
            }

            //document.body.classList.add("noscroll");
            
        })
        
        this.emitter.on('close', (param) => {
            this.emittedParam = param
            this.close()
        })
        
        this.emitter.on('confirm', (param) => {
            this.emittedParam = param
            this.confirm()
        })
    }
    
};
</script>
<style  lang="scss" scoped>
.revomodal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 4999;

    &-bg{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.2);
    }
    
    &-content {
        position: absolute;
        top: 3rem;
        display: flex;
        flex-direction: column;
        width: 60%;
        pointer-events: auto;
        outline: 0;
    }

    .modal-body {
        background: white;
        border-top: 1rem solid #ffcb05;
        border-radius: 0.8rem;
        margin-bottom: 5rem;
    }

    .btn-close {
        background: none;
        box-shadow: none !important;
        font-size: 4rem;
        line-height: 1;
        padding: 0;
        position: absolute;
        right: 1rem;
        top: 1rem;
        opacity: 1 !important;
        z-index: 500;
    }
}
</style>